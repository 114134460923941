import { default as account_45lookup4OChhVx1xsMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as addMjTVJUZrW3Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deleteddrrIodkOecMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_93NF1NwraASoMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as indexsQcehr9ov0Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_932G7JnMCgN5Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companiessWBxBgVYbDMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_93xOQrhqSqkhMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as indexizmmV5zz8WMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as indexhuM2xRPuIbMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboard3iB7gvmI2BMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addO7mRHZcsdTMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_93ruKOD3kiggMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_93DGOrFFfw16Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as indexAvQ0HJguWcMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_93jo2eZOPkAkMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as indexFS0GAWMUaSMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as indexWdj2GXqgoxMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as indexggQrsF0vfgMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as indexiMWxI9cvoLMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexTlEel8cfwbMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as indexKT7L09HU8jMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logswZE3WDi2giMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as addWjZa0jkdrpMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45membersNh5MvfVCpCMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as indexH9NyI2KqpCMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as indexNLdlRmhJxwMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_935NRsUGW9vqMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as index2DgoPlYTkyMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_93Q3fFAxkRnaMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emailhNeAvUxhEqMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45listsqJW0V6ufELMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexWrUzdyr2CyMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93qL2xEGYpXVMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as membersHfQ5G0LsxLMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statisticsRKuBdjn3rYMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_93cUQyH0p3hNMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as indexJ1vdpxn62UMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addpqlTskKnd7Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93BmlvkMaTejMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as indexpAwJINSJ4NMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_93v0aGU4ATemMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45codeGypcfvwRrTMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screenings5aMrxCAjndMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as indexTqzTitoFf0Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as inviteqG3HAUpvI8Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_93LfeCOqEskuMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as ticketstotBKqKeatMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as indexLzZZOK9EuiMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboardZTRZAhQ1nxMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as indexPNLLMnBKYhMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93WvQzvUH2stMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as addzkvtLMikWTMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addIcg3eyjsozMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as indexTa3C5qJ2ZRMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companies4gz6nRGcpFMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as indexkiYRPTqzbwMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordEqmGBC7cqrMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as index0XOVqT8SSzMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoicesAc2OkFuNfvMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as applysQ2yZNaTIQMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93OY9vLytiQcMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_93sfXgBTaTxYMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as indexVpdR4k2uNLMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as indexdLCriXchv9Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93V6sBfyRM3HMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as membershXuNc7SdD4Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as indexQij0jWVBafMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_93kr0eJoDOctMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_934CXGjqYICPMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexPQyuH0crKFMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_93hipLzEzWjpMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_93SUaKQBmN35Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetzNEzIzp6LYMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as indexCYaVh41PVaMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_93SQmcFjdzhCMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_938bCwdB7v03Meta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_93asSmk16vTRMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callbackXZLOgO0GXYMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logouteTyo8oEDmIMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as indexxAAAWStsnLMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_93e0q0NrCQLeMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93kGO50tUQnYMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_93vyckO76H1LMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexk2nyb9grHHMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93vghQyoBlkwMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_93LJZN5KXnmPMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as tickets9dhvPdL5vJMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as vote3rK6rDyuToMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubgqDrFkSBtrMeta } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgqDrFkSBtr } from "/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookup4OChhVx1xsMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companiessWBxBgVYbDMeta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: addMjTVJUZrW3Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deleteddrrIodkOecMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_93NF1NwraASoMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: indexsQcehr9ov0Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_932G7JnMCgN5Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_93xOQrhqSqkhMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: indexizmmV5zz8WMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: indexhuM2xRPuIbMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboard3iB7gvmI2BMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addO7mRHZcsdTMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_93ruKOD3kiggMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_93DGOrFFfw16Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indexAvQ0HJguWcMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_93jo2eZOPkAkMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: indexFS0GAWMUaSMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logswZE3WDi2giMeta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: indexWdj2GXqgoxMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: indexggQrsF0vfgMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: indexiMWxI9cvoLMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexTlEel8cfwbMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: indexKT7L09HU8jMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: membersHfQ5G0LsxLMeta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: addWjZa0jkdrpMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45membersNh5MvfVCpCMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: indexH9NyI2KqpCMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: indexNLdlRmhJxwMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_935NRsUGW9vqMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: index2DgoPlYTkyMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_93Q3fFAxkRnaMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45listsqJW0V6ufELMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexWrUzdyr2CyMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93qL2xEGYpXVMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statisticsRKuBdjn3rYMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_93cUQyH0p3hNMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: indexJ1vdpxn62UMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45codeGypcfvwRrTMeta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addpqlTskKnd7Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93BmlvkMaTejMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: indexpAwJINSJ4NMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_93v0aGU4ATemMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: ticketstotBKqKeatMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: indexTqzTitoFf0Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: inviteqG3HAUpvI8Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93LfeCOqEskuMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: indexLzZZOK9EuiMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboardZTRZAhQ1nxMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: indexPNLLMnBKYhMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93WvQzvUH2stMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: addzkvtLMikWTMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addIcg3eyjsozMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: indexTa3C5qJ2ZRMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: indexkiYRPTqzbwMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordEqmGBC7cqrMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0XOVqT8SSzMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93OY9vLytiQcMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_93sfXgBTaTxYMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: indexdLCriXchv9Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93V6sBfyRM3HMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: indexQij0jWVBafMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_93kr0eJoDOctMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexPQyuH0crKFMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_93hipLzEzWjpMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_93SUaKQBmN35Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetzNEzIzp6LYMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexCYaVh41PVaMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_93SQmcFjdzhCMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_938bCwdB7v03Meta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_93asSmk16vTRMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callbackXZLOgO0GXYMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logouteTyo8oEDmIMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: tickets9dhvPdL5vJMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93vghQyoBlkwMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: vote3rK6rDyuToMeta || {},
    component: () => import("/tmp/codebuild/output/src3709/src/codestar-connections.us-east-1.amazonaws.com/git-http/761018895050/us-east-1/1b8820c6-4769-4670-ae45-509f888024e3/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubgqDrFkSBtrMeta?.name,
    path: "/judge-application",
    component: component_45stubgqDrFkSBtr
  },
  {
    name: component_45stubgqDrFkSBtrMeta?.name,
    path: "/profile",
    component: component_45stubgqDrFkSBtr
  },
  {
    name: component_45stubgqDrFkSBtrMeta?.name,
    path: "/login",
    component: component_45stubgqDrFkSBtr
  },
  {
    name: component_45stubgqDrFkSBtrMeta?.name,
    path: "/join",
    component: component_45stubgqDrFkSBtr
  },
  {
    name: component_45stubgqDrFkSBtrMeta?.name,
    path: "/memberships/my_membership",
    component: component_45stubgqDrFkSBtr
  }
]